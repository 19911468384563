export const useTypeaheadSearch = (controller) => {
  Object.assign(controller, {
    search() {
      const searchValue = this.searchInputTarget.value

      fetch(this.autocompleteUrl(searchValue), {
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((json) => {
          const typeaheadElements = json.data.map((result) => {
            const element = document.createElement("a")
            element.innerHTML = this.searchResultDisplay(result)
            element.classList.add(
              "d-block",
              "link-dark",
              "p-1",
              "merge-select-link",
              "text-decoration-none",
            )
            element.href = "#"
            element.addEventListener("click", (event) => {
              event.preventDefault()
              this.selectedResultTarget.value = result.id
              this.searchInputTarget.value = this.searchResultDisplay(result)
              this.resultListTarget.innerHTML = ""
              if (typeof this.onSelect === "function") {
                this.onSelect()
              }
            })
            return element
          })

          this.resultListTarget.innerHTML = ""
          typeaheadElements.forEach((element) => {
            this.resultListTarget.appendChild(element)
          })
        })
    },
  })
}
