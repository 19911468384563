import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-on-input-change"
export default class extends Controller {
  static targets = ["input", "initiallyHiddenContent"]

  static get HIDDEN_CLASS() {
    return 'd-none'
  }

  connect() {
    this.initialInputValue = this.inputTarget.value
    this.handleInputEvent = this.handleInputEvent.bind(this)
    this.inputTarget.addEventListener("input", this.handleInputEvent)
  }

  handleInputEvent(event) {
    if(event.target.value === this.initialInputValue) {
      this.initiallyHiddenContentTarget.classList.add(this.constructor.HIDDEN_CLASS)
    } else {
      this.initiallyHiddenContentTarget.classList.remove(this.constructor.HIDDEN_CLASS)
    }
  }
}
