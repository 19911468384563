import { useTypeaheadSearch } from "../mixins/typeahead_search"
import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = [
    "searchInput",
    "resultList",
    "selectedResult",
    "suggestion",
    "newTrainerCta",
  ]

  connect() {
    useTypeaheadSearch(this)
    this.search = debounce(this.search.bind(this), 300)
    this.onSelect = this.onSelect.bind(this)
  }

  autocompleteUrl(searchTerm) {
    return `/trainers/autocomplete?search=${searchTerm}`
  }

  searchResultDisplay(trainer) {
    return `${trainer.name} (ID: ${trainer.id})`
  }

  clearNewTrainerCta() {
    if (this.hasNewTrainerCtaTarget) {
      this.newTrainerCtaTarget.innerHTML = ""
    }
  }

  onSelect() {
    this.clearNewTrainerCta()
  }

  acceptSuggestion(event) {
    event.preventDefault()
    this.selectedResultTarget.value = parseInt(event.target.dataset.trainerId)
    this.searchInputTarget.value = event.target.text
    this.clearNewTrainerCta()
  }
}
