import { useTypeaheadSearch } from "../mixins/typeahead_search"
import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = ["searchInput", "resultList", "selectedResult"]

  connect() {
    useTypeaheadSearch(this)
    this.search = debounce(this.search.bind(this), 300)
  }

  autocompleteUrl(searchTerm) {
    return `/intl_bios/autocomplete?search=${searchTerm}`
  }

  searchResultDisplay(bio) {
    return `${bio.name} (TM ID: ${bio.trackman_id})`
  }
}
